import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout hasBanner={true}>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/banners/upton-acoustic.jpg')`,
            backgroundPosition: `center`,
            backgroundSize: `contain`,
            backgroundRepeat: `no-repeat`,
            backgroundColor: `#01020F`,
            height: `600px`
          }}
        >
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
